import { useEffect, useState } from "react";

import calculateScrollbarWidth from "utils/calculateScrollbarWidth";

const useScrollbarWidth = (): number | undefined => {
    const [sbw, setSbw] = useState(calculateScrollbarWidth());

    useEffect(() => {
        if (typeof sbw !== "undefined") {
            return;
        }

        const raf = requestAnimationFrame(() => {
            setSbw(calculateScrollbarWidth());
        });

        return () => cancelAnimationFrame(raf);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return sbw;
};

export default useScrollbarWidth;
